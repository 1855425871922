import * as React from "react";

const FooterComponent = () => {
  return (
    <footer class="footer bg-dark pt-5 pb-4 text-light text-center">
      © All rights reserved.
      <br />
    </footer>
  );
};

export default FooterComponent;
