import "../scss/App.scss";
import * as React from "react";
import woodlandImage2 from "../images/joyce-g-c9peBmC_yzQ-unsplash.jpg";
import woodlandImage from "../images/olivier-guillard-FKJgBUDoVC0-unsplash.jpg";
import finaldrivepartsLogo from "../images/finaldriveparts.webp";
import pioneerLogo from "../images/pioneer-logo--cropped.png";
import texasFinalDriveLogo from "../images/texasfinaldrive-logo.png";
import bulldozerImage from "../images/gerold-hinzen-WoZs8gGyQBY-unsplash.jpg";

import Layout from "../components/layout";

const header = {
  width: "100vw",
  background: "black",
  overflow: "hidden",
  backgroundPosition: "bottom",
  background:
    "#C33764" /* fallback colour. Make sure this is just one solid colour. */,
  background:
    "-webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0,0,0,0.5)), url(" +
    woodlandImage +
    ")",
  background:
    "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(" +
    woodlandImage +
    ")" /* The least supported option. */,
};

const IndexPage = () => {
  return (
    <>
      <Layout pageTitle="Manufacturers">
        <header
          style={header}
          class="position-relative overflow-hidden p-2 p-md-2 m-md-0 text-center bg-light"
        >
          <div class="col-md-10 p-lg-5 mx-auto my-5 text-white">
            <h1 class="display-4 font-weight-normal">Woodland Fluid Power</h1>
            <p class="lead font-weight-normal">
              The Woodland Fluid Power family of companies brings excellence to
              the secondary market for heavy equipment
            </p>
          </div>
        </header>

        <section class="row g-0">
          <div class="col-md-6 px-3 px-md-5 py-5 bg-white">
            <div
              class="mx-auto py-lg-5 text-dark"
              style={{ maxWidth: "35rem" }}
            >
              <a class="text-dark" href="https://www.pioneerfinaldrives.com">
                <h2 class="h3 pb-3">PioneerFinalDrives.com</h2>
              </a>

              <p class="fs-sm pb-3 text-dark">
                PioneerFinalDrives.com is bringing manufacturing back to
                America. We are proud of being a leader in remanufacturing and
                assembly. We deliver drives made and remade in America.
              </p>
              <a href="https://www.pioneerfinaldrives.com">
                <img src={pioneerLogo}></img>
              </a>
            </div>
          </div>
          <div class="col-md-6 px-3 px-md-5 py-5 bg-dark">
            <div
              class="mx-auto py-lg-5 text-light"
              style={{ maxWidth: "35rem" }}
            >
              <a class="text-light" href="https://shop.finaldriveparts.com">
                <h2 class="h3 pb-3">FinalDriveParts.com</h2>
              </a>
              <p class="fs-sm pb-3 text-light">
                FinalDriveParts.com delivers quality work and knowledgeable
                customer service to get our clients up and running as soon as
                possible at the most competitive prices.
              </p>
              <a href="https://shop.finaldriveparts.com">
                <img src={finaldrivepartsLogo}></img>
              </a>
            </div>
          </div>
        </section>

        <section class="row g-0">
          <div
            class="col-md-6"
            style={{
              minHeight: "12rem",
            }}
          >
            <img class="img-fluid" src={bulldozerImage}></img>
          </div>
          <div class="col-md-6 px-3 px-md-5 py-5 bg-white">
            <div
              class="mx-auto py-lg-5 text-dark"
              style={{ maxWidth: "35rem" }}
            >
              <a class="text-dark" href="https://www.texasfinaldrive.com">
                <h2 class="h3 pb-3">TexasFinalDrive.com</h2>
              </a>
              <p class="fs-sm pb-3 text-dark">
                With a regional focus on Texas and the surrounding states,
                TexasFinalDrive.com specializes in repair and replacement of
                Hydraulics parts and motors, including variable, fixed, axial
                and radial.
              </p>
              <a href="https://www.texasfinaldrive.com">
                <img src={texasFinalDriveLogo}></img>
              </a>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default IndexPage;
