import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import NavbarComponent from "../components/navbar";
import FooterComponent from "./footer";

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <div>
      {children}
      <FooterComponent></FooterComponent>
    </div>
  );
};

export default Layout;
